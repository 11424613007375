<template>
    <div class="full-height-layout fill overflow-y-initial">
        <div>
            <div class="px-4 pt-2 mt-2" data-test="carrierListTitle" v-if="value && serviceLevelList">
                <span>Servicelevels ({{numberOfSelectedServiceLevels}} of {{serviceLevelList.length}} selected)</span>
            </div>
        </div>
        <div class="fill full-height-layout overflow-y-initial">
            <ag-grid-vue
                class="ag-grid fill ag-theme-material overflow-y-initial border"
                data-test="carrierListTitleDataTable"
                :gridOptions="gridOptions"
                @grid-ready="onGridReady"
                :columnDefs="columnDefs"
                :defaultColDef="defaultColDef"
                :suppressRowClickSelection="true"
                :enableRangeSelection="true"
                v-model="serviceLevelList"
                rowSelection="multiple"
                row-model-type="clientSide"
                :suppressCellSelection="true"
                @selection-changed="onSelectionChanged"
                @cell-value-changed="onSelectionChanged"
            ></ag-grid-vue>
        </div>
    </div>
</template>
<script>
import { AgGridVue } from 'ag-grid-vue'
import 'ag-grid-enterprise'
import currencyToSymbolMap from 'currency-symbol-map/map'

export default {
    name: 'CarrierList',
    components: { AgGridVue },
    props: ['value', 'locationServiceLevelsPromise'],
    data () {
        return {
            availableCurrencies: Object.entries(currencyToSymbolMap).map(([code, sign]) => {
                return code
            }),
            columnDefs: null,
            defaultColDef: null,
            gridOptions: null,
            serviceLevelList: null,
            gridApi: null,
            numberOfSelectedServiceLevels: 0,
            quickFilter: '',
        }
    },

    methods: {
        onGridReady (params) {
            this.gridApi = params.api
            this.locationServiceLevelsPromise.then(() => {
                this.$store.dispatch('carriers/fetchAllServiceLevels').then(() => {
                    setTimeout(() => {
                        this.setSelectedCarriers()
                    }, 10)
                })
            })
        },
        onSelectionChanged (pars) {
            const selectedRows = this.gridApi.getSelectedRows().map(row => {
                delete row['dateCreated']
                delete row['dateUpdated']
                delete row['dateRevoked']

                if (!row.supplierContract) {
                    row.supplierContract = '{}'
                }
                return row
            })
            this.$emit('input', selectedRows)
            this.numberOfSelectedServiceLevels = selectedRows.length
            this.gridApi.redrawRows()
        },
        setSelectedCarriers () {
            this.serviceLevelList = Object.freeze(
                this.$store.state.carriers.serviceLevels.map(row => {
                    const localServiceLevel = this.value.find(serviceLevel => serviceLevel.serviceLevelId === row.serviceLevelId)
                    let pickupTime = null
                    if (localServiceLevel) {
                        if (localServiceLevel.pickupTime) {
                            pickupTime = localServiceLevel.pickupTime
                        } else {
                            pickupTime = Object.values(localServiceLevel.shipTo || {})[0] || null
                        }
                    }
                    return {
                        ...row,
                        supplierContract: (localServiceLevel ? localServiceLevel.supplierContract : ''),
                        currency: (localServiceLevel ? localServiceLevel.currency : 'EUR'),
                        shipTo: (localServiceLevel ? localServiceLevel.shipTo : {}),
                        pickupTime: pickupTime || '18:00',
                    }
                })
            )

            setTimeout(() => {
                this.gridApi.hideOverlay()
                this.gridApi.forEachNode((serviceLevel) => {
                    if (this.value.some(setServiceLevel => setServiceLevel.serviceLevelId === serviceLevel.data.serviceLevelId)) {
                        serviceLevel.setSelected(true)
                    }
                })

                this.gridApi.sizeColumnsToFit()
            }, 10)
        },
    },

    beforeMount () {
        this.gridOptions = {
            stopEditingWhenGridLosesFocus: true,
        }
        this.columnDefs = [
            {
                headerCheckboxSelection: true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
                headerName: 'Service level',
                field: 'serviceLevelName',
                editable: false,
                valueFormatter: null,
                cellStyle: null,
            },
            {
                headerName: 'Supplier Contract',
                field: 'supplierContract',
                maxWidth: 200,
            },
            {
                headerName: 'Pickup time',
                field: 'pickupTime',
            },
            {
                headerName: 'Currency',
                field: 'currency',
                cellEditor: 'agRichSelectCellEditor',
                cellEditorParams: {
                    values: this.availableCurrencies,
                },
                maxWidth: 130,
            },
        ]

        this.defaultColDef = {
            enableRowGroup: false,
            enablePivot: false,
            enableValue: true,
            sortable: false,
            resizable: true,
            filter: true,
            singleClickEdit: false,
            valueFormatter: (params) => {
                return params.node.selected ? params.value : ''
            },
            editable: (cell) => {
                return cell.node.selected
            },
            cellStyle: (params) => {
                return {
                    cursor: params.node.selected ? 'pointer' : 'not-allowed',
                }
            },
        }
    },
    watch: {
        quickFilter (value) {
            this.gridApi.setQuickFilter(value)
        },
    },
}
</script>
<style lang="scss" scoped>
    .overflow-y-initial {
        overflow-y: initial;
    }
    .card-header {
        padding-bottom: 10px;
    }
</style>
