<template>
    <div class="full-height-layout fill">
        <form class="form form--supplier-save full-height-layout fill" is="json-form" :action="action" @error="onError" @success="saveFacility" :values="values">
            <template slot-scope="form">
                <mercur-stepper :active-step.sync="stepsActive" class="fill pl-5 pr-5 pt-5">
                    <mercur-step id="office" title="Facility details">
                        <mercur-card class="mx-4 mb-3">
                            <location-office-fields :location="form.data.location" :v="$v.values.location" location-type="FACILITY" :show-continue-button="false"></location-office-fields>
                        </mercur-card>
                    </mercur-step>
                    <mercur-step id="workingdays" title="Working days">
                        <mercur-card class="mx-4 mb-3">
                            <working-days-fields v-if="form.data.facility.workingDays" v-model="form.data.facility.workingDays" :enable-cutoff-time="true" :enable-working-hours="true"></working-days-fields>
                        </mercur-card>
                    </mercur-step>
                    <mercur-step id="carriers" title="Carrier Service Levels">
                        <mercur-card class="full-height-layout fill mx-4 mb-3">
                            <carrier-list class="fill" v-model="form.data.facility.serviceLevels" :location-service-levels-promise="locationServiceLevelsPromise"></carrier-list>
                        </mercur-card>
                    </mercur-step>
                </mercur-stepper>

                <div class="text-right py-3">
                    <mercur-button class="btn text-uppercase" @click="nextStep" v-if="stepsActive !== 'carriers'">Next step</mercur-button>
                    <mercur-button class="btn btn-raised btn-yellow text-uppercase" type="submit" :disabled="form.loading || $v.$invalid">Save facility</mercur-button>
                </div>

                <p v-if="form.errors.message" v-text="form.errors.message"></p>

                <div data-test="loading" class="loading-overlay" v-if="form.loading">
                    <mercur-progress-bar indeterminate></mercur-progress-bar>
                </div>
            </template>
        </form>
    </div>
</template>

<script>
import JsonForm from '@/components/utils/JsonForm'
import { mapGetters } from 'vuex'
import CONFIG from '@root/config'

import LocationOfficeFields from '@/components/LocationOfficeFields'
import WorkingDaysFields from '@/components/WorkingDaysFields'
import LocationBase from '@/views/supplier/locations/LocationBase'
import CarrierList from '@/components/carriers/CarrierList'

export default {
    name: 'SupplierAddOfficeView',
    components: { LocationOfficeFields, WorkingDaysFields, JsonForm, CarrierList },
    mixins: [ LocationBase ],
    computed: {
        ...mapGetters('suppliers', ['getSupplierById']),
        action () {
            let url = CONFIG.API.ROUTES.SUPPLIERS.LOCATION_ADD

            if (this.formType === 'edit') {
                url = CONFIG.API.ROUTES.SUPPLIERS.LOCATION_UPDATE.replace('{locationId}', this.$route.params.facilityId)
            }

            return url.replace('{supplierId}', this.supplierId)
        },
        formType () {
            return this.$route.params.facilityId ? 'edit' : 'add'
        },
    },

    data () {
        return {
            carriers: [],
            stepsActive: 'office',
            values: {
                location: {
                    certifications: [],
                    facilitySettings: {},
                },
                facility: {
                    workingDays: null,
                    carriers: null,
                },
            },
            url: CONFIG.API.ROUTES.SUPPLIERS.GET.replace('{supplierId}', this.supplierId),
        }
    },

    methods: {
        ...mapGetters('carriers', ['getCarriers']),
        nextStep () {
            if (this.stepsActive === 'office') {
                this.$v.values.location.$touch()
                if (this.$v.values.location.$invalid) {
                    return
                }
                this.stepsActive = 'workingdays'

                return
            }

            if (this.stepsActive === 'workingdays') {
                this.stepsActive = 'carriers'
            }
        },
        onError (error) {
            this.$root.$emit('notification:global', {
                message: `Saving carrier failed. Please try again.`,
                type: 'error',
                errors: { errors: error },
            })
        },
        setDone (id, index) {
            this.steps.errors[this.steps.active] = null
            if (this.$v) {
                this.$v.$touch()

                if (this.$v.$invalid) {
                    this.steps[id] = false
                    this.steps.errors[this.steps.active] = 'Please check the errors'

                    return
                }
            }

            this.steps[id] = true

            if (index) {
                this.steps.active = index
            }
        },
        saveFacility (data) {
            if (!data.message) {
                return
            }

            if (data.message === 'location.creation.successful') {
                this.$root.$emit('notification:global', {
                    message: 'Facility added',
                })

                this.$router.push({
                    name: 'SupplierLocations',
                    params: {
                        supplierId: this.supplierId,
                    },
                })
            }

            if (data.message === 'location.update.successful') {
                this.$root.$emit('notification:global', {
                    message: 'Facility saved',
                })

                this.$router.push({
                    name: 'SupplierLocations',
                    params: {
                        supplierId: this.supplierId,
                    },
                })
            }
        },
    },
    created () {
        if (this.formType === 'add') {
            this.values.facility.parentId = this.$route.params.officeId
            this.values.location.parentId = this.$route.params.officeId
            this.values.location.locationType = 'FACILITY'
            this.values.facility.workingDays = []
            this.values.facility.serviceLevels = []
            this.values.facility.locationType = 'FACILITY'
        }
    },
}
</script>

<style scoped lang="scss">
.form--supplier-save {
    padding-left: 10px;
    padding-right: 10px;
}
</style>
